import React from 'react'
// Types
import { PaginationProps } from '../../../../types/pages'
import { PostPreviewWithCategoriesProps } from '../../../../types/blog'
// Components
import { Row, Col } from 'react-styled-flexboxgrid'
import { Box, Text } from '../../../../atoms'
import Pagination from './Pagination'
import { PostPreview } from '../index'

interface PaginatedPosts extends PaginationProps {
  posts: PostPreviewWithCategoriesProps[]
  prevLabel?: string
  nextLabel?: string
}

const PaginatedPosts: React.FC<PaginatedPosts> = ({ posts, ...pagination }) => {
  return (
    <Box as={'section'}>
      {posts.length > 0 ? (
        <Row>
          {posts.map((p) => (
            <Col key={p.id} xs={12} sm={6} md={4}>
              <Box mb={[6, 7, 8]}>
                <PostPreview data={p} />
              </Box>
            </Col>
          ))}
        </Row>
      ) : (
        <Box minHeight={400}>
          <Text type={'regular'} fontWeight={'ultraBold'} textAlign={'center'}>
            No post
          </Text>
        </Box>
      )}

      <Pagination {...pagination} />
    </Box>
  )
}

export default PaginatedPosts
