import React from 'react'
// Types
import { PostPreviewWithCategoriesProps } from '../../../../types/blog'
// Contexts
import { useLocale } from '../../../../hooks/useLocale'
// Components
import { Row, Col } from 'react-styled-flexboxgrid'
import { Box, Image, Heading, Text } from '../../../../atoms'
import { Link } from 'gatsby'
import { FeaturedPostBox, FeaturedPostImageWrapper } from './FeaturedPost.styled'

interface FeaturedPostProps {
  data: PostPreviewWithCategoriesProps
}

const FeaturedPost: React.FC<FeaturedPostProps> = ({ data }) => {
  const { localeSlug } = useLocale()
  const { slug, title, thumbnail, categories, readingTime, metaFormatted } = data
  return (
    <FeaturedPostBox>
      <Row middle={'xs'}>
        <Col xs={12} md={6}>
          <Box zIndex={1} pr={[0, 0, 0, 7]} mb={[5, 6, 0]}>
            <Link to={`/${localeSlug}${slug}`} style={{ display: 'block' }}>
              <FeaturedPostImageWrapper
                position={'relative'}
                borderRadius={'small'}
                overflow={'hidden'}
                backgroundColor={'violetLight'}
              >
                <Image
                  alt={thumbnail.alt || title}
                  image={thumbnail.gatsbyImageData}
                  style={{
                    borderRadius: '8px',
                    overflow: 'hidden',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                  imgStyle={{ borderRadius: '8px' }}
                />
              </FeaturedPostImageWrapper>
            </Link>
          </Box>
        </Col>
        <Col xs={12} md={6}>
          <Box pl={[0, 0, 0, 8]}>
            {categories && categories.length > 0 && (
              <Box mb={2}>
                {categories.map((c, i) => (
                  <Link key={c.id} to={`/${localeSlug}${c.pageInfo.slug}`}>
                    <Text as={'span'} color={'darkBlue'} type={'small'} fontWeight={'ultraBold'}>
                      {c.name}
                    </Text>
                    {i + 1 < categories.length && (
                      <Box display={'inline'} mx={1}>
                        <Text as={'span'} type={'small'}>
                          {'•'}
                        </Text>
                      </Box>
                    )}
                  </Link>
                ))}
              </Box>
            )}

            <Link to={`/${localeSlug}${slug}`} style={{ display: 'block' }}>
              <Box mb={2} minHeight={['unset', 'unset', 54]}>
                <Heading as={'h2'} type={'display'}>
                  {title}
                </Heading>
              </Box>
              <Box mt={2}>
                <Text type={'small'} color={'grey50'}>
                  {`${readingTime} • ${metaFormatted.firstPublishedAt || metaFormatted.createdAt}`}
                </Text>
              </Box>
            </Link>
          </Box>
        </Col>
      </Row>
    </FeaturedPostBox>
  )
}

export default FeaturedPost
