import React from 'react'
import { Box, BoxProps, Heading, HeadingProps } from '../../../../atoms'
import styled from 'styled-components'

export const PostPreviewBox = styled.article``

export const PostPreviewImageWrapper: React.FC<BoxProps> = styled(Box)<BoxProps>`
  padding-top: calc(100% / 1.4038);
  transition: opacity 0.25s ease-in-out;

  ${PostPreviewBox}:hover & {
    opacity: 0.85;
  }
`

export const PostPreviewTitle: React.FC<HeadingProps> = styled(Heading)<HeadingProps>`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: opacity 0.25s ease-in-out;

  ${PostPreviewBox}:hover & {
    opacity: 0.85;
  }
`
