import React from 'react'
// Types
import { PostPreviewWithCategoriesProps } from '../../../../types/blog'
// Contexts
import { useLocale } from '../../../../hooks/useLocale'
// Components
import { Box, Text, Image } from '../../../../atoms'
import { Link } from 'gatsby'
import { PostPreviewBox, PostPreviewImageWrapper, PostPreviewTitle } from './PostPreview.styled'

interface PostPreviewProps {
  data: PostPreviewWithCategoriesProps
}

const PostPreview: React.FC<PostPreviewProps> = ({ data }) => {
  const { localeSlug } = useLocale()
  const { slug, title, thumbnail, categories, readingTime, metaFormatted } = data
  return (
    <PostPreviewBox>
      <Box borderRadius={'small'} overflow={'hidden'} zIndex={1}>
        <Link to={`/${localeSlug}${slug}`} style={{ display: 'block' }}>
          <PostPreviewImageWrapper position={'relative'} backgroundColor={'violetLight'}>
            <Image
              alt={thumbnail.alt || title}
              image={thumbnail.gatsbyImageData}
              style={{
                borderRadius: '8px',
                overflow: 'hidden',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
              imgStyle={{ borderRadius: '8px' }}
            />
          </PostPreviewImageWrapper>
        </Link>
      </Box>

      <Box mt={4}>
        {categories && categories.length > 0 && (
          <Box mb={2}>
            {categories.map((c, i) => (
              <Link key={c.id} to={`/${localeSlug}${c.pageInfo.slug}`}>
                <Text as={'span'} color={'darkBlue'} type={'small'} fontWeight={'ultraBold'}>
                  {c.name}
                </Text>
                {i + 1 < categories.length && (
                  <Box display={'inline'} mx={1}>
                    <Text as={'span'} type={'small'}>
                      {'•'}
                    </Text>
                  </Box>
                )}
              </Link>
            ))}
          </Box>
        )}

        <Link to={`/${localeSlug}${slug}`} style={{ display: 'block' }}>
          <Box mb={2} minHeight={['unset', 'unset', 54]}>
            <PostPreviewTitle as={'h3'} type={'heading4'}>
              {title}
            </PostPreviewTitle>
          </Box>
          <Box mt={2}>
            <Text type={'small'} color={'grey50'}>
              {`${readingTime ? `${readingTime} • ` : ''}${metaFormatted.firstPublishedAt || metaFormatted.createdAt}`}
            </Text>
          </Box>
        </Link>
      </Box>
    </PostPreviewBox>
  )
}

export default PostPreview
