import React from 'react'
// Types
import { PaginationProps } from '../../../../../types/pages'
// Components
import { Box, Button, Flex } from '../../../../../atoms'

const Pagination: React.FC<
  PaginationProps & {
    prevLabel?: string
    nextLabel?: string
  }
> = ({ totalPages, nextLabel = 'Next', prevLabel = 'Prev', nextPagePath, previousPagePath }) => {
  if (!!(totalPages && totalPages > 0)) {
    return (
      <Flex mt={[5, 6, 7]} justifyContent={'space-between'} alignItems={'center'} flexWrap={'wrap'}>
        <Box flex={'0 0 auto'} my={2}>
          <Button
            label={prevLabel}
            size={'sm'}
            buttonType={'outline'}
            disabled={!previousPagePath}
            link={previousPagePath ? { id: 'pagination-prevPath', name: prevLabel, slug: previousPagePath } : undefined}
          />
        </Box>
        <Box flex={'0 0 auto'} my={2}>
          <Button
            label={nextLabel}
            size={'sm'}
            buttonType={'outline'}
            disabled={!nextPagePath}
            link={nextPagePath ? { id: 'pagination-nextPath', name: nextLabel, slug: nextPagePath } : undefined}
          />
        </Box>
      </Flex>
    )
  }
  return null
}

export default Pagination
