import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { AuthorProps } from '../../../../types/blog'
import { Box, Flex, Image, Link, Text } from '../../../../atoms'
import { useLocale } from '../../../../hooks/useLocale'

interface AuthorCardProps {
  author: AuthorProps
}

function AuthorCard(props: AuthorCardProps): JSX.Element {
  const { name, pageInfo, role, profilePicture, description, socials } = props.author
  const { localeSlug } = useLocale()

  return (
    <Box p={5} borderWidth={1} borderColor={'black'} borderStyle={'solid'} borderRadius={'small'} boxShadow={'card'}>
      <Flex>
        {profilePicture && (
          <Box flex={'0 0 auto'} mr={3} width={48} height={48} overflow={'hidden'} borderRadius={'circle'}>
            <Image
              alt={profilePicture.alt ?? name}
              title={profilePicture.title ?? name}
              image={profilePicture.gatsbyImageData}
              style={{ width: '100%', height: '100%' }}
              imgStyle={{ objectFit: 'cover' }}
            />
          </Box>
        )}
        <Box flex={'1'}>
          <GatsbyLink to={`/${localeSlug}${pageInfo.slug}`}>
            <Text fontWeight={'bold'}>{name}</Text>
          </GatsbyLink>
          {role && (
            <Box mt={'2px'}>
              <Text type={'small'} color={'grey50'}>
                {role}
              </Text>
            </Box>
          )}

          {description && (
            <Box mt={3}>
              <Text type={'regular'}>{description}</Text>
            </Box>
          )}

          {socials.length > 0 && (
            <Flex mt={5} alignItems={'center'}>
              {socials.map((s, idx) => (
                <Box key={s.id} ml={idx > 0 ? 2 : undefined} mr={idx + 1 < socials.length ? 2 : undefined}>
                  <Link href={s.url} target={'_blank'} rel={'noreferrer noopener'} style={{ display: 'block' }}>
                    <Image
                      alt={s.icon.alt || s.name}
                      src={s.icon.url}
                      width={s.icon.width}
                      height={s.icon.height}
                      draggable={'false'}
                    />
                  </Link>
                </Box>
              ))}
            </Flex>
          )}
        </Box>
      </Flex>
    </Box>
  )
}

export default AuthorCard
