import React from 'react'

import { AuthorProps } from '../../../../types/blog'
import { Flex, Box, Image, Text } from '../../../../atoms'
import { useLocale } from '../../../../hooks/useLocale'
import { Link } from 'gatsby'

type AuthorLightProps = Pick<AuthorProps, 'profilePicture' | 'name' | 'role' | 'pageInfo'>

function AuthorLight(props: AuthorLightProps): JSX.Element {
  const { profilePicture, name, pageInfo, role } = props
  const { localeSlug } = useLocale()
  return (
    <Flex alignItems={'center'}>
      <Box mr={3} flex={'0 0 48px'} height={48} borderRadius={'circle'} overflow={'hidden'}>
        <Image
          alt={profilePicture.alt ?? name}
          title={profilePicture.title ?? name}
          image={profilePicture.gatsbyImageData}
          style={{ width: '100%', height: '100%' }}
          imgStyle={{ objectFit: 'cover' }}
        />
      </Box>
      <Box>
        <Link to={`/${localeSlug}${pageInfo.slug}`}>
          <Text fontWeight={'bold'}>{name}</Text>
        </Link>
        {role && (
          <Text type={'small'} color={'grey50'}>
            {role}
          </Text>
        )}
      </Box>
    </Flex>
  )
}

export default AuthorLight
