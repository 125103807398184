import React from 'react'
import { Box, BoxProps } from '../../../../atoms'
import styled from 'styled-components'

export const FeaturedPostBox = styled.article``

export const FeaturedPostImageWrapper: React.FC<BoxProps> = styled(Box)<BoxProps>`
  padding-top: calc(100% / 1.238);
  transition: opacity 0.25s ease-in-out;

  ${FeaturedPostBox}:hover & {
    opacity: 0.85;
  }
`
